/**
 * 代理详情
 */
import dayjs from "dayjs";
import * as Service from "@/service/auth";
import {
  ExpressesEmit,
  affirmShipments,
  checkSourceCode,
} from "@/service/order";
import { Dialog, Toast } from "vant";
import { mapState } from "vuex";
import { scancode } from "@/utils/wx-promise";
import logisticsPopup from "@/components/order/logistics-popup/index.vue";
import { getUrlParams, ISQINGBULUO } from "@/utils/common.js";
import { deliverRetailOrder } from "@/service/retail-order/index.js";
import { getToken } from "@/utils/localStorage.js";

export default {
  name: "order-logistics",
  components: { logisticsPopup },
  methods: {
    // 重新扫码
    scanagain() {
      this.showLogisticsPopup = false;
      // 如果当前的status是
      switch (this.status) {
        // 溯源码的重新录入
        case 0:
        case 1:
        case 2:
        case 3:
          break;
      }
    },
    //切换是否全显订单详情
    toggleDetail() {},
    // 关闭弹窗
    closePopup() {
      this.showLogisticsPopup = false;
      // 重定向到我的订单审核页面
      this.$router.replace({
        name: "order-review",
      });
    },
    // 确定订单
    async send() {
      if (this.sourceCodeList.length == 0 || !this.sourceCodeList) {
        Toast("至少需要一个溯源码");
        return;
      }
      if (!this.logisticsCode) {
        Toast("物流单号不能为空");
        return;
      } else if (!this.logisticsCompony.name) {
        Toast("请选择物流公司");
        return;
      } else {
        try {
          await Dialog.confirm({
            message:
              this.$route.query.from === "retail"
                ? `确定发货吗～`
                : `确定同意${this.row.userName}的订单么`,
          });
          const localdate = new Date();
          let str = dayjs(localdate).format("YYYY-MM-DD HH:mm:ss");
          let sourceList = {};
          this.sourceCodeList.forEach((e) => {
            sourceList[e.sourceCode] = e.type;
          });

          if (this.$route.query.from === "retail") {
            await deliverRetailOrder({
              trackCompany: this.logisticsCompony.name,
              trackingNumber: this.logisticsCode,
              sourceCode: sourceList,
              code: this.row.code,
              token: getToken(),
              juniorId: this.$route.query.juniorId,
            }).then((res) => {
              this.$router.push({
                name: "retail-delivery-success",
                query: { orderId: this.row.code },
              });
            });
            return;
          }

          await affirmShipments({
            trackingInfoPo: {
              expressId: this.logisticsCompony.id,
              expressName: this.logisticsCompony.name,
              trackingNumber: this.logisticsCode,
              com: this.logisticsCompony.com,
              createdAt: str,
            },
            TracingSourceCode: JSON.stringify(
              this.sourceCodeList.map((e) => {
                return { code: e.sourceCode };
              })
            ), //朔源码:序列化
            sourceCode: sourceList,

            trackingNumber: this.logisticsCode, //物流单号,
            expressName: this.logisticsCompony.name, //物流公司名字
            operateUserId: this.userId, //操作人
            state: 1, //0 表示已发货
            type: 1, //1表示的是代理端
            id: this.row.id,
          });
          this.status = 4;
          this.showLogisticsPopup = true;
        } catch (error) {
          console.log(error);
        }
      }
    },
    //确定物流公司
    confirmCompany(e, value, index) {
      this.showCompanyChoose = false;
      this.logisticsCompony = this.companyList[this.currentIndex];
      // 选择
    },
    //查询订单详情
    getDetail() {
      this.send();
    },
    // 展示物流公司的选择
    toShowCompanyChoose() {
      this.showCompanyChoose = true;
      this.currentIndex = 0; //清空选择
    },
    //popup中物流公司的改动
    onCompanyChange(e, value, index) {
      this.currentIndex = index;
    },

    // 获取物流单号
    async getLogisticsCode() {
      let code = await scancode();
      this.logisticsCode = code.split(",")[1]; //第二个才是
    },
    // 将溯源码添加到朔源码列表中
    async addSourceCode() {
      const code = this.sourceCode && this.sourceCode.trim();
      if (!this.validateSourceCode(this.sourceCode && this.sourceCode.trim())) {
        Toast("该溯源码不是12位或者16位不能发货");
        return false;
      }
      if (this.sourceCode.trim()) {
        // 校验朔源码是否有效，如果有，则添加
        if (
          this.sourceCodeList.find((e) => {
            return e.sourceCode === this.sourceCode;
          })
        ) {
          Toast("该溯源码已经在列表中");
          return;
        }
        // 暂时不调用接口，修改自己测试
        // const { codeList } = await checkSourceCode({ tracingSourceCode: this.sourceCode });
        // switch (codeList[0].state) {
        //   // 匹配成功，type为1表示盒
        //   case '1':
        //   case 1:
        //     this.sourceCodeList.push({ sourceCode: this.sourceCode, size: `1${codeList[0].type ==1 ? '盒' : '箱'}`, type: codeList[0].type, });
        //     Toast(codeList[0].message);
        //     break;

        //   default:
        //     Toast(codeList[0].message);
        //     break;
        // }
        this.sourceCodeList.push({
          sourceCode: this.sourceCode,
          size: `1箱`,
          type: "1",
        });
      }
    },
    //获取溯源码
    async getLogisticsSource() {
      let code = await scancode();
      console.log("code", code);
      const params = getUrlParams(code);
      if (code.indexOf("?") >= 0 && params["fw"]) {
        //  加入判断，如果识别出来的是网址，解析网址 http://cx.1.net/?fw=9834085332679670
        this.sourceCode = params["fw"];
      } else {
        this.sourceCode = code.split(",")[1] || code;
      }
    },
    //校验溯源码是否正确
    validateSourceCode(sourceCode) {
      if (!/^(\d{16}|\d{12})$/.test(sourceCode)) {
        Toast(`存在溯源码${sourceCode}不是12位数字或者16位数字，不能发货～`);
        return false;
      }
      return true;
    },
  },

  data() {
    return {
      detailToggle: false, //false表示不全部显示订单清单，true 表示全显
      sourceCodeList: [], //溯源码列表
      row: {}, //当前正在操作的审核订单
      status: 1, //0：溯源码-重复，1：朔源-失败,2:物流单号-失败，3：确认发货，4：确认订单
      showLogisticsPopup: false, //是否展示弹窗
      companyList: [], //物流公司列表
      currentIndex: -1, //物流公司的下标
      showCompanyChoose: false, //是否展示物流公司选择
      sourceCode: "", //溯源的码
      logisticsCompony: {}, //物流公司
      logisticsCode: "", //物流单号
      detail: {},
      showDetail: false, //是否展示个人信息的弹窗
      operarow: {}, //当前操作的列
      reason: "",
      rejectShow: false, //reject弹出层
      showList: [],
      finished: false,
      offset: 0, //页数，0开始
      limit: 10, //每页的调数
      loading: false,
      systemList: [], //用户的升级列表
      canUpgrade: false, // 能否给当前代理升级
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.user.user.id, // 我的ID
    }),
  },
  async mounted() {
    if (this.$route.query.from === "retail") {
      this.row = this.$route.query;
    } else {
      this.row = JSON.parse(this.$route.query.row);
    }
    const data = await ExpressesEmit();
    this.companyList = data;
  },
};
